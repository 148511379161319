import { Operation, type Patient } from '@/types'
import { type paths } from '../generated/schema'

import { type PatientRegistrationCreate } from '../types/PatientRegistrationCreate'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatient = fetcher
  .path('/api/Patient/{patientId}')
  .method('get')
  .create()

export const getPatient = async (
  patientId: number,
  getToken: any
): Promise<Patient> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  return (await setupGetPatient({ patientId, 'api-version': undefined }))?.data
}

const setupGetPatients = fetcher
  .path('/api/Patient/All/{clinicId}')
  .method('get')
  .create()

export const getPatients = async (
  clinicId: number = 0,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatients({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetPatients.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createPatientRegistration = fetcher
  .path('/api/Patient')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatient = async (
  patient: PatientRegistrationCreate,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  return (await createPatientRegistration(patient)).data
}

const setupPatientSearch = fetcher
  .path('/api/Patient/Search')
  .method('get')
  .create()
//
export const searchPatient = async (
  query: string,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupPatientSearch({ query }))?.data
  } catch (error) {
    if (error instanceof setupPatientSearch.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupUpdatePatient = fetcher
  .path('/api/Patient/{patientId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatient = async (
  patientId: number,
  Patient: PatientRegistrationCreate,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  await setupUpdatePatient({ patientId, ...Patient })
}

export const patchPatient = async (
  patientId: number,
  operations: Operation,
  getToken: any
): Promise<any> => {
  const API_URL = process.env.API_URL
  const token = await getToken({ template: 'UrgentIQ' })

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token as string}`,
    Origin: 'null',
  }

  const url = `${API_URL!}/api/Patient/${patientId}`

  const body = JSON.stringify(operations)
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers,
      body,
    })

    if (!response.ok) {
      const errorData = await response.json()
      if (response.status === 400) {
        throw new Error(`Validation errors: ${JSON.stringify(errorData)}`)
      } else if (response.status === 500) {
        throw new Error(`Server error: ${JSON.stringify(errorData)}`)
      }
    }
    // no data to return on success
  } catch (error) {
    console.error('Error making the PATCH request:', error)
    throw error // Rethrow the error for further handling, if necessary
  }
}
